// Created By ND
//All Default Colors:
const ColorsHelper = {
  whiteClr: "#FFFFFF",
  bgWhiteClr: "#E5E5E5",
  bgWhiteClrO95: "#E5E5E595",
  white1: "#f2f2f7",
  white2: "#efefef",
  white3: "#fafafa",
  white4: "#F3F2F7",
  white5: "#D9D9D9",
  white6: "#FFFFFF85",
  whiteCyan: "#f3f3f3",
  blackClr: "#000000",
  white7: "#00000020",
  white8: "#E9ECEF",
  blackLightClr: "#404040",
  blackLight2Clr: "#212121",
  blackLight3Clr: "#232020",
  blackLight4Clr: "#2a2a2a",
  blackLight5Clr: "rgba(0, 0, 0, 0.23)",
  blackLight6Clr: "rgba(0, 0, 0, 0.35)",
  blackLight7Clr: "rgba(0, 0, 0, 0.0)",
  secondaryColor: "#F7921E",
  shadowClr: "rgba(0,0,0,0.4)",
  globalBGColor: "#F5F5F5",
  lightGreen: "#A2EFB3",
  lightGreen2: "rgba(40, 199, 111, 0.12)",
  green: "#3FCA5E",
  green1: "#00AD11",
  green2: "#28C76F40",
  green3: "#28C76F",
  green4: "#c0f0d1",
  green5: "#00a36d",
  green6: "#28a745",
  green7: "#4caf50",
  green8: "#7bfbb9",
  grey1: "#6E6B7B",
  grey2: "#DADADA",
  grey3: "#AAAAAA",
  grey4: "#E2E2E2",
  grey5: "#858585",
  grey6: "#707070",
  grey7: "#515050",
  grey8: "#AAAAAA",
  grey10: "#EBE9F1",
  grey11: "#B9B9C3",
  grey12: "#C4C4C4",
  grey13: "#F6FBFF",
  grey14: "#CFCFCF",
  grey15: "#D1D1D1",
  grey16: "#696969",
  grey17: "#747373",
  grey18: "#808080",
  grey19: "#93939380",
  grey20: "#DCDCDC",
  grey21: "#F6F5FF",
  grey22: "#B7B0FF66",
  grey23: "#b7b7b7",
  grey24: "#a19f9f",
  grey25: "#A9A9A9",
  grey26: "#DFDFDF",
  grey27: "#898989",
  grey28: "#BBBBBB",
  grey29: "#9F9F9F",
  redLight: "#C72828",
  red: "#FF1010",
  pureRed: "#FF0000",
  red1: "#EA545540",
  red2: "#EA5455",
  red3: "#d32f2f",
  red4: "#dc3545",
  red5: "#ff446a",
  red6: "#ffab96",
  red7: "#df3434",
  lightRed: "rgba(199, 40, 40, 0.12)",
  lightRed2: "#EFA1A1",
  red20Opacity: "#FF101020",
  brownColor: "#5e5873",
  transparentColor: "00FFFFFF",
  cardColor1: "#F7D41E",
  cardColor2: "#9C2CF3",
  cardColor3: "#F8497E",
  cardColor4: "#3FCA5E",
  cardColor5: "#979797",
  cardColor6: "#391B0B",
  lightYellow: "rgba(255, 159, 67, 0.12)",
  yellow: "#FF9F43",
  yellow1: "#ffc107",
  statusAciveGreen: "#28C76F",
  statusInAciveRed: "#EA5455",
  statusInAwayYellow: "#FF9F43",
  statusInDoNotDisturbGray: "#82868B",
  purple: "#7367F0",
  purple1: "#948CE6",
  purple2: "#9E95F5",
  purple3: "#5E5873",
  chatbg1: "#bfbfff",
  chatbg2: "#7cfcf8",
  chatbg3: "#0FAABD",
  chatbg4: "#F7921E",
  chatbg4O12: "rgba(247, 146, 30, 0.12)",
  chatbg5: "#707070",
  chatbg6: "#d9a668",
  chatbg7: "#63bbf2",
  chatbg8: "#a38aff",
  chatbg9: "#fa89d8",
  chatbg10: "#fc7ce3",
  chatChr1: "#b80294",
  chatChr2: "#b80294",
  chatChr3: "#5402b8",
  chatChr4: "#5402b8",
  chatChr5: "#020bb8",
  chatChr6: "#025ab8",
  chatChr7: "#01786c",
  chatChr8: "#f1f1f1",
  chatChr9: "#002e0b",
  chatChr10: "#780101",
  boxBadgeYellow: "#ff9f43",
  boxBadgeShadow: "#ff9f43a6",
  boxShadow: "#888888",
  boxShadowOp: "rgba(0, 0, 0, 0.35)",
  boxShadowOp2: "rgba(0,0,0,0.19)",
  boxShadowOp3: "rgba(0,0,0,0.23)",
  blue: "#1976d2",
  blue1: "#5E5873",
  blue2: "#17a2b8",
  blue3: "#007bff",
  blue4: "#1c95f5",
  blue5: " #50e4fb",
  blue6: " #2c9bec",
  blue7: " #1F81B6",
  aqua: "#00CFE8",
  qrgrdntClr1: "#032ca3",
  qrgrdntClr2: "#8107c8",
  qrgrdntClr3: "#740493",
  rsBtnColor: "#2E2E2E",
  orange1: "#F7921E",
  chrtDvcDataAndClr: "#CE602A",
  chrtDvcDataIosClr: "#5B1A07",
  chrtDvcDataDeskClr: "#191919",

  chrtConMthdDEmailClr: "#F5B221",
  chrtConMthdDSmsClr: "#DE942A",
  chrtConMthdDGoogleClr: "#DA7F29",
  chrtConMthdDFaceBookClr: "#CE602A",
  chrtConnectionClr: "#F7921E",
  chrtConnection2Clr: "#DE942A",

  timLineclr1: "#fbcf7e",
  timLineclr2: "#edd46c",
  timLineclr3: "#eba64b",
  timLineclr4: "#fb943c",
  timLineclr5: "#77490d",
  timLineclr6: "#624747",
  timLineclr7: "#5f83b0",
  timLineclr8: "#84caae",
  timLineclr9: "#bcd5e2",

  hDshBrdClr1: "#F5B221",
  hDshBrdClr2: "#F7921E",
  hDshBrdClr3: "#DE942A",
  hDshBrdClr4: "#DA7F29",
  hDshBrdClr5: "#CE602A",
  hDshBrdClr6: "#5B1A07",
  hDshBrdClr7: "#191919",
  noteBoxBg: "rgba(115, 103, 240, 0.12)",
  lightGreenBg: "rgba(40, 199, 111, 0.12)",
  lightRedBg: "rgba(239, 161, 161, 0.20)",
  lightOrng: "rgba(247, 146, 30, 0.1)",
  orang1: "#f79e1b",
  orang2: "#ffb775",
};
export default ColorsHelper;
