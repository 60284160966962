// Createdb By ND

// React Imports
import React, { useState } from "react";

// MUI Imports
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FormHelperText from "@mui/material/FormHelperText";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import Checkbox from "@mui/material/Checkbox";
import Menu from "@mui/material/Menu";
import FormControlLabel from "@mui/material/FormControlLabel";

// Third Party Imports
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  trackPromise,
  manuallyResetPromiseCounter,
} from "react-promise-tracker";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";

// Custom Imports
import InformationSettings from "./InformationSettings";

// Graphql Imports
import {
  GET_MENU_LIST,
  MENU_STATUS_UPDATE,
  EDIT_MENU,
  DELETE_MENU,
  CHANGE_MENU_POSITION,
  UPDATE_MENU_COLLECTION,
} from "./MenusGraphQl";

// Custom Styles Imports
import mvStyles from "./MenuView.styles";

// Helper Imports
import Fonts from "../../../Helper/Fonts";
import urlsHelper from "../../../Helper/Urls";
import AppUtils from "../../../Utils/AppUtils";

// Custom Lazy Loaded Components
const CustTextField = React.lazy(
  () => import("../../../Components/CusTextField/CustTextField.component")
);
const CustomAlertDialog = React.lazy(
  () =>
    import("../../../Components/CustomAlertDialog/CustomAlertDialog.component")
);

const defaultFields = [
  {
    name: "firstname",
    title: "First Name",
    is_checked: false,
    is_mendatory: false,
  },
  {
    name: "lastname",
    title: "Last Name",
    is_checked: false,
    is_mendatory: false,
  },
  {
    name: "mobile",
    title: "Mobile Number",
    is_checked: true,
    is_mendatory: true,
  },
  { name: "dob", title: "Birth date", is_checked: true, is_mendatory: true },
  { name: "email", title: "Email", is_checked: false, is_mendatory: false },
  { name: "gender", title: "Gender", is_checked: false, is_mendatory: false },
];

const MenuList = (props: any) => {
  // Props
  const { sdvRef, ConstantsHelper, staticIcons } = props;

  // States
  const [dragItems, setDragItems] = useState<any>([]);
  const [oldDragIds, setOldDragIds] = useState<any>([]);
  const [apiLoader, setApiLoader] = useState(false);
  const [hoverdiv, setHoverDiv] = useState<any>(null);
  const [editMenuObj, setEditMenuObj] = useState<any>(null);
  const [nameCheckError, setNameCheckError] = useState<{
    valid: boolean;
    helperTxt: string;
  } | null>(null);
  const dgDefVal = {
    open: false,
    actionType: "",
    dataObject: null,
    activeIndex: -1,
    dialogTitle: "",
    confirmMessage: "",
  };
  const [loader, setLoader] = useState<boolean>(false);
  const [deleteDialogData, setDeleteDialogData] = useState(dgDefVal);
  const [dialog, openDialog] = useState<{ open: boolean; item: any }>({
    open: false,
    item: {},
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openQr, setOpenQr] = useState<{ open: boolean; item: any }>({
    open: false,
    item: {},
  });
  const [currItem, setCurrItem] = useState<any>(null);
  const [fieldArr, setFieldArr] = useState<any>(defaultFields);

  // Hooks
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { height } = AppUtils.useContainerDimensions(sdvRef);

  // Media Query
  // const XLScreen = useMediaQuery("(min-width: 1441px) and (max-width: 2560px)");
  const LLScreen = useMediaQuery("(min-width: 1115px) and (max-width: 1440px)");
  const LMScreen = useMediaQuery("(min-width: 845px) and (max-width: 1114px)");
  const LSScreen = useMediaQuery("(min-width: 600px) and (max-width: 844px)");
  const TScreen = useMediaQuery("(min-width: 426px) and (max-width: 599px)");
  const SScreen = useMediaQuery("(min-width: 281px) and (max-width: 425px)");
  const GoldFold = useMediaQuery("(max-width: 280px)");
  const IPhoneXR = useMediaQuery("(width: 414px)");
  const IPhone12Pro = useMediaQuery("(width: 390px)");
  const Pixel5 = useMediaQuery("(width: 393px)");
  const SamsungGalaxyS8Plus = useMediaQuery("(width: 360px)");
  const SamsungGalaxyS20Ultra = useMediaQuery("(width: 412px)");
  const IPadAir = useMediaQuery("(width: 820px)");
  const IPadMini = useMediaQuery("(width: 768px)");
  const SurfaceDuo = useMediaQuery("(width: 540px)");
  const MQ1200 = useMediaQuery("(max-width: 1200px)");
  const MQ900 = useMediaQuery("(max-width: 900px)");
  const MQ1600 = useMediaQuery("(max-width: 1600px)");

  // Api Calls
  const [menuList] = useLazyQuery(GET_MENU_LIST);
  const [menuStatusChange] = useMutation(MENU_STATUS_UPDATE);
  const [editMenu] = useMutation(EDIT_MENU);
  const [deleteMenu] = useMutation(DELETE_MENU);
  const [changeMenuPosition] = useMutation(CHANGE_MENU_POSITION);
  const [updateMenuCollection] = useMutation(UPDATE_MENU_COLLECTION);

  const options = [
    {
      name: "Edit Menu",
      icon: staticIcons.EditGrey,
      click: (e: any) => {
        e.stopPropagation();
        setEditMenuObj(currItem);
      },
    },
    {
      name: "Preview",
      icon: staticIcons.Show,
      click: () =>
        window.open(
          `${urlsHelper.serverUrl}${AppUtils.getDomainName()}/vmq/${
            currItem?.id
          }`,
          "_blank"
        ),
    },
    {
      name: "Generated QR",
      icon: staticIcons.MenuQr,
      click: () => setOpenQr({ open: true, item: currItem }),
    },
    {
      name: "View Menu",
      icon: staticIcons.Preview,
      click: (e: any) => {
        e.stopPropagation();
        AppUtils.setLocationData();
        manuallyResetPromiseCounter();
        navigate(`/menu/category/${hoverdiv?.id}`);
      },
    },
    {
      name: "Remove Menu",
      icon: staticIcons.Del,
      click: (e: any) => {
        e.stopPropagation();
        setDeleteDialogData({
          open: true,
          actionType: "delete_menu_value",
          dataObject: currItem,
          activeIndex: -1,
          dialogTitle: currItem?.name ? currItem?.name : "Menu",
          confirmMessage: createConfirmMessage([currItem?.id]),
        });
      },
    },
  ];

  // Get Category List
  React.useEffect(() => {
    trackPromise(loadInitialMenuData());
  }, []);

  // Category List Api Call
  const loadInitialMenuData = async () => {
    try {
      const menuListResponse: any = await promiseMenuList({
        location_id: AppUtils.getLocationId(),
      });
      const { error, data } = menuListResponse;
      if (AppUtils.checkDirectValue(data)) {
        if (AppUtils.checkFieldValue(data, "menuList")) {
          if (data?.menuList) {
            const { status } = data?.menuList;
            if (status) {
              const menuData = data?.menuList?.data;
              if (menuData) {
                if (oldDragIds?.length === 0 && menuData?.length > 0) {
                  const itemsId = menuData.map((item: any) => {
                    return Number(item?.id);
                  });
                  setOldDragIds(itemsId);
                }
                setDragItems(menuData);
                return;
              }
              return;
            }
            setDragItems([]);
          }
        }
        return;
      }
      if (AppUtils.checkDirectValue(error)) {
        enqueueSnackbar(AppUtils.catchErrorHandling(error), {
          variant: "error",
        });
        return;
      }
      return;
    } catch (e: any) {
      enqueueSnackbar(AppUtils.catchErrorHandling(e), { variant: "error" });
      return;
    }
  };

  const promiseMenuList = (passData: any) => {
    const promise = new Promise(async (resolve) => {
      await menuList({
        variables: passData,
        onCompleted: (data) => {
          resolve({ data: data, error: null });
        },
        onError: (error) => {
          resolve({ data: null, error: error });
        },
      });
    });
    return promise;
  };

  // Status Change Menu Api Call
  const menuStatusChangeData = async (passData: any) => {
    try {
      const menuStatusChangeResponse = await menuStatusChange({
        variables: passData,
      });
      const { errors, data } = menuStatusChangeResponse;
      if (!errors) {
        if (data?.menuStatusChange) {
          const { status, message } = data.menuStatusChange;
          if (status) {
            enqueueSnackbar(message, { variant: "success" });
            setDeleteDialogData(dgDefVal);

            trackPromise(loadInitialMenuData());
            return;
          }
          enqueueSnackbar(AppUtils.checkErrorMsg(message), {
            variant: "error",
          });
          return;
        }
        return;
      }
      enqueueSnackbar(AppUtils.catchErrorHandling(errors), {
        variant: "error",
      });
      return;
    } catch (e) {
      enqueueSnackbar(AppUtils.catchErrorHandling(e), { variant: "error" });
      return;
    }
  };

  // Edit Menu Api Call
  const editMenuData = async (passData: any) => {
    try {
      setApiLoader(true);
      const editMenuResponse = await editMenu({
        variables: passData,
      });
      const { errors, data } = editMenuResponse;
      if (!errors) {
        if (data?.editMenu) {
          const { status, message, error } = data.editMenu;
          if (status) {
            enqueueSnackbar(message, { variant: "success" });
            setEditMenuObj(null);
            setApiLoader(false);
            setCurrItem(null);
            trackPromise(loadInitialMenuData());
            return;
          }
          setApiLoader(false);
          let isError = true;
          if (AppUtils.checkDirectValue(error)) {
            if (AppUtils.checkFieldValue(error, "name")) {
              setNameCheckError({ valid: true, helperTxt: error["name"] });
              isError = false;
            }
          }
          if (isError) {
            enqueueSnackbar(AppUtils.checkErrorMsg(message), {
              variant: "error",
            });
          }
          return;
        }
        setApiLoader(false);
        return;
      }
      setApiLoader(false);
      enqueueSnackbar(AppUtils.catchErrorHandling(errors), {
        variant: "error",
      });
      return;
    } catch (e) {
      setApiLoader(false);
      enqueueSnackbar(AppUtils.catchErrorHandling(e), { variant: "error" });
      return;
    }
  };

  // Update Collection Api Call
  const updateCollection = async (passData: any) => {
    try {
      setLoader(true);
      const editMenuResponse = await updateMenuCollection({
        variables: passData,
      });
      const { errors, data } = editMenuResponse;
      if (
        AppUtils.checkDirectValue(data) &&
        AppUtils.checkDirectValue(data?.updateMenuCollection) &&
        data?.updateMenuCollection?.status
      ) {
        setDragItems((prevItems: any) =>
          prevItems.map((val: any) =>
            val?.id === String(passData?.menu_id)
              ? {
                  ...val,
                  is_data_collection: passData?.is_data_collection,
                  additional_fields: passData?.additional_fields,
                }
              : val
          )
        );
        setLoader(false);
        enqueueSnackbar(data?.updateMenuCollection?.message, {
          variant: "success",
        });

        return;
      }
      setLoader(false);
      enqueueSnackbar(AppUtils.catchErrorHandling(errors), {
        variant: "error",
      });
    } catch (e) {
      enqueueSnackbar(AppUtils.catchErrorHandling(e), { variant: "error" });
      return;
    }
    setLoader(false);
  };

  const createConfirmMessage = (id: any) => {
    if (id && id?.length > 0) {
      if (id?.length === 1) {
        return ConstantsHelper.delMT;
      } else {
        return ConstantsHelper.delMMT;
      }
    } else {
      return ConstantsHelper.delMT;
    }
  };

  // Delete Menu Api Call
  const deleteMenuData = async (passData: any) => {
    try {
      setApiLoader(true);
      const deleteMenuResponse = await deleteMenu({
        variables: passData,
      });
      const { errors, data } = deleteMenuResponse;
      if (!errors) {
        if (data?.deleteMenu) {
          const { status, message } = data.deleteMenu;
          if (status) {
            enqueueSnackbar(message, { variant: "success" });
            setApiLoader(false);
            setDeleteDialogData(dgDefVal);
            setDragItems((prev: any) => {
              return prev.filter(
                (item: any) => item?.id !== String(passData?.id)
              );
            });

            return;
          }
          setApiLoader(false);
          enqueueSnackbar(AppUtils.checkErrorMsg(message), {
            variant: "error",
          });
          return;
        }
        setApiLoader(false);
        return;
      }
      setApiLoader(false);
      enqueueSnackbar(AppUtils.catchErrorHandling(errors), {
        variant: "error",
      });
      return;
    } catch (e) {
      setApiLoader(false);
      enqueueSnackbar(AppUtils.catchErrorHandling(e), { variant: "error" });
      return;
    }
  };

  // DraggbleItem View
  const draggableItem = (item: any, index: any) => {
    return (
      <GridItem
        key={index}
        style={{
          padding: "1.25rem",
          ...((SScreen || GoldFold) && { padding: "0rem" }),
          ...(LSScreen && { padding: "0rem" }),
          ...((LMScreen || LLScreen) && { padding: "0rem 1.25rem" }),
          ...((SScreen || TScreen || LSScreen || GoldFold) &&
            item?.id === hoverdiv?.id && {
              height: "5.5vh",
              position: "relative",
            }),
          ...((IPhoneXR || IPhone12Pro || Pixel5 || SamsungGalaxyS20Ultra) &&
            item?.id === hoverdiv?.id && {
              height: "4vh",
              position: "relative",
            }),
          ...((SamsungGalaxyS8Plus || SurfaceDuo) &&
            item?.id === hoverdiv?.id && {
              height: "4.7vh",
              position: "relative",
            }),
          ...((IPadAir || IPadMini) &&
            item?.id === hoverdiv?.id && {
              height: "2vh",
              position: "relative",
            }),
        }}
        onClick={() => editMenuObj === null && setEditMenuObj(null)}
      >
        <Box sx={mvStyles?.mvItemsBx} onMouseOver={() => setHoverDiv(item)}>
          <Box sx={mvStyles.mvItemsTitleMBx}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                position: "relative",
                top: "-17px",
                left: "-7px",
              }}
            >
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={Boolean(anchorEl) ? "long-menu" : undefined}
                aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                aria-haspopup="true"
                onClick={(event: any) => {
                  setAnchorEl(event.currentTarget);
                  setCurrItem(item);
                }}
                sx={{ p: "0 !important", color: "black" }}
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
            <Box
              sx={[
                mvStyles.mvItemsTitleBx,
                { position: "relative", top: "-9px" },
              ]}
            >
              {editMenuObj?.id === item?.id ? (
                <React.Suspense fallback={<></>}>
                  <CustTextField
                    margin="dense"
                    inputRef={(input: any) => input && input.focus()}
                    fullWidth
                    sx={mvStyles.mvNameTF}
                    txttitle={""}
                    id={"name"}
                    placeholder={"Pizza, Burger, Baverages, etc"}
                    type="text"
                    size="small"
                    value={editMenuObj?.name}
                    onChange={(e: any) => {
                      setNameCheckError(null);
                      if (e?.target?.value?.length < 31) {
                        setEditMenuObj({
                          ...editMenuObj,
                          name: e.target.value,
                        });
                      }
                    }}
                    // onBlur={() => {
                    //   if (
                    //     editMenuObj?.name?.length > 0 &&
                    //     editMenuObj?.name !== item.name
                    //   ) {
                    //     trackPromise(
                    //       editMenuData({
                    //         id: Number(editMenuObj.id),
                    //         name: editMenuObj?.name,
                    //         location_id: AppUtils.checkFieldValue(
                    //           editMenuObj,
                    //           "location_id"
                    //         )
                    //           ? Number(editMenuObj?.location_id)
                    //           : AppUtils.getLocationId(),
                    //       })
                    //     );
                    //   } else {
                    //     setEditMenuObj(null);
                    //   }
                    // }}
                  />
                </React.Suspense>
              ) : (
                <>
                  <Typography sx={mvStyles.mvItemTitle}>
                    {item?.name}
                  </Typography>
                </>
              )}
              {editMenuObj?.id === item?.id && (
                <LoadingButton
                  sx={{
                    ...mvStyles.mvSTblBtn,
                    ...(nameCheckError?.valid && { marginTop: "0rem" }),
                    ...(apiLoader ? mvStyles.mSpBtn : mvStyles.mWSpBtn),
                  }}
                  variant="contained"
                  type="button"
                  loading={apiLoader}
                  disabled={apiLoader || editMenuObj?.name?.length === 0}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (
                      editMenuObj?.name?.length > 0 &&
                      editMenuObj?.name !== item.name
                    ) {
                      trackPromise(
                        editMenuData({
                          id: Number(editMenuObj.id),
                          name: editMenuObj?.name,
                          location_id: AppUtils.checkFieldValue(
                            editMenuObj,
                            "location_id"
                          )
                            ? Number(editMenuObj?.location_id)
                            : AppUtils.getLocationId(),
                        })
                      );
                    } else {
                      setEditMenuObj(null);
                    }
                  }}
                >
                  {ConstantsHelper.saveTitle}
                </LoadingButton>
              )}
              {editMenuObj?.id !== item?.id && (
                <Box sx={{ marginRight: "0rem" }}>
                  <GreenSwitch
                    checked={item?.is_active}
                    onClick={() => {
                      trackPromise(
                        menuStatusChangeData({
                          id: Number(item?.id),
                          status: item?.is_active === true ? false : true,
                        })
                      );
                    }}
                  />
                </Box>
              )}
            </Box>
            {editMenuObj?.id === item?.id && (
              <FormHelperText
                sx={{ marginLeft: "0.875rem" }}
                error={nameCheckError?.valid}
              >
                {nameCheckError?.helperTxt}
              </FormHelperText>
            )}
          </Box>
        </Box>
        {item?.id === hoverdiv?.id && <HoverDiv item={item} />}
      </GridItem>
    );
  };

  // Green Switch
  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
      color: "#df3434",
      "&:hover": {
        backgroundColor: alpha("#df3434", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase + .MuiSwitch-track": {
      backgroundColor: "#EFA1A1",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#3FCA5E",
      "&:hover": {
        backgroundColor: alpha("#3FCA5E", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#A2EFB3",
    },
  }));

  const HoverDiv = (props: any) => {
    // Props
    const { item } = props;

    return (
      <Box
        sx={[
          mvStyles.mvItemsHoverBx,
          { cursor: !item?.is_active ? "default" : "pointer" },
        ]}
      >
        <FormControlLabel
          sx={{
            "& > span": {
              fontFamily: Fonts.TitleFontFamily,
              fontSize: "0.9rem",
              fontWeight: 500,
              "&.Mui-disabled": {
                color: "rgba(255, 255, 255, 0.6) !important",
              },
            },
          }}
          disabled={loader || !item?.is_active}
          control={
            <Checkbox
              sx={{
                color: "white",
                "&.Mui-checked": { color: "white" },
              }}
              checked={item?.is_data_collection}
              onChange={() => {
                const selectedFields: any = {
                  field: {},
                  field_required: {},
                };

                defaultFields.forEach((field: any) => {
                  selectedFields.field[field.name] = field.is_checked;
                  selectedFields.field_required[field.name] =
                    field.is_mendatory;
                });
                updateCollection({
                  menu_id: parseInt(item?.id),
                  is_data_collection: !item?.is_data_collection,
                  additional_fields: item?.is_data_collection
                    ? null
                    : JSON.stringify(selectedFields),
                });
              }}
            />
          }
          label="Enable Data Collection"
        />
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Checkbox />

          <Typography sx={[mvStyles.mvBtnComponent, { fontSize: "0.9rem" }]}>
            Enable Data Collection
          </Typography>
        </Box> */}
        {item?.is_data_collection && (
          <IconButton
            disabled={!item?.is_active}
            onClick={() => {
              openDialog({ open: true, item: item });
              if (AppUtils.checkDirectValue(item?.additional_fields)) {
                const fields = JSON.parse(item?.additional_fields);
                const arr =
                  Array.isArray(fieldArr) &&
                  fieldArr?.length > 0 &&
                  fieldArr?.map((field: any) => ({
                    ...field,
                    is_checked: fields.field[field.name] || false,
                    is_mendatory: fields.field_required[field.name] || false,
                  }));

                setFieldArr(arr);
              }
            }}
          >
            <Box
              component={"img"}
              src={staticIcons.settingsWhite}
              sx={{ width: "1.4rem" }}
            />
          </IconButton>
        )}
        {/* <Button
        sx={mvStyles.mvBtnComponent}
        onClick={(e) => {
          e.stopPropagation();
          setEditMenuObj(hoverdiv);
        }}
      >
        {ConstantsHelper.editTitle}
      </Button>
      <Button
        sx={mvStyles.mvBtnComponent}
        onClick={(e) => {
          e.stopPropagation();
          AppUtils.setLocationData();
          manuallyResetPromiseCounter();
          navigate(`/menu/category/${hoverdiv?.id}`);
        }}
      >
        {ConstantsHelper.viewTitle}
      </Button>
      <Button
        sx={mvStyles.mvBtnComponent}
        onClick={(e) => {
          e.stopPropagation();
          deleteRowClick(hoverdiv);
        }}
      >
        {ConstantsHelper.deleteTitle}
      </Button> */}
      </Box>
    );
  };

  // Ondragend
  const onChange = async (
    sourceId: any,
    sourceIndex: any,
    targetIndex: any,
    targetId: any
  ) => {
    const items = swap(dragItems, sourceIndex, targetIndex);

    let isUndefined: boolean = false;
    await Object.keys(items).forEach(function (key: any) {
      if (items[key] === undefined) {
        isUndefined = true;
      }
    });
    if (isUndefined) {
      return;
    }
    setDragItems(items);

    const itemsId = items.map((item: any) => {
      return Number(item.id);
    });

    let nCall = true;
    if (oldDragIds?.length > 0 && itemsId?.length > 0) {
      if (JSON.stringify(oldDragIds) === JSON.stringify(itemsId)) {
        nCall = false;
      }
    }
    if (nCall) {
      trackPromise(changeMenuPositionData({ id: itemsId }));
    }
  };

  // Change Position Menu Api Call
  const changeMenuPositionData = async (passData: any) => {
    try {
      const changeMenuPositionResponse = await changeMenuPosition({
        variables: passData,
      });
      const { errors, data } = changeMenuPositionResponse;
      if (!errors) {
        if (data?.changeMenuPosition) {
          const { status, message } = data.changeMenuPosition;
          if (status) {
            setOldDragIds(passData.id);
            trackPromise(loadInitialMenuData());
            return;
          }
          enqueueSnackbar(AppUtils.checkErrorMsg(message), {
            variant: "error",
          });
          return;
        }
        return;
      }
      enqueueSnackbar(AppUtils.catchErrorHandling(errors), {
        variant: "error",
      });
      return;
    } catch (e) {
      enqueueSnackbar(AppUtils.catchErrorHandling(e), { variant: "error" });
      return;
    }
  };

  const AddMenuBtn = () => (
    <GridItem
      style={{
        padding: "1.25rem",
        gridColumnStart: "-1",
        ...((SScreen || GoldFold) && { padding: "0", height: "7rem" }),
        ...(TScreen && { padding: "1.25rem", height: "9.5rem" }),
        ...(LSScreen && { padding: "0rem", height: "6.5rem" }),
        ...((LMScreen || LLScreen) && {
          padding: "0rem 1.25rem",
          height: "6.5rem",
        }),
      }}
      onMouseDown={(e) => e.stopPropagation()}
      onTouchMove={(e) => e.stopPropagation()}
    >
      <Box
        sx={mvStyles.mvBlackBx}
        draggable={false}
        onClick={(e: any) => {
          e.stopPropagation();
          AppUtils.setLocationData();
          manuallyResetPromiseCounter();
          navigate(`/menu/add-menu`);
        }}
      >
        <Box sx={{ ...mvStyles.mvItemsTitleBx, marginLeft: "1.5rem" }}>
          <Typography
            sx={mvStyles.mvNMenuTx}
            style={{
              ...((GoldFold || LMScreen) && { marginLeft: "0rem" }),
            }}
          >
            {ConstantsHelper.addNMT}
          </Typography>
          <Box
            sx={mvStyles.mvPlusBtnBx}
            style={{ ...(GoldFold && { marginRight: "3rem" }) }}
          >
            <IconButton>
              <img
                style={mvStyles.mvPlusBtnImg}
                src={staticIcons.PlusBlackIcon}
                alt="plus"
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </GridItem>
  );

  return (
    <>
      <GridContextProvider onChange={onChange}>
        {Array.isArray(dragItems) && dragItems?.length > 0 ? (
          <GridDropZone
            id="items"
            boxesPerRow={MQ1200 ? (MQ900 ? 1 : 2) : MQ1600 ? 3 : 4}
            rowHeight={
              SScreen || TScreen || LSScreen ? 140 : GoldFold ? 135 : 175
            }
            style={{ height: height - 40, marginTop: 20 }}
          >
            {dragItems.map((item: any, index: any) =>
              draggableItem(item, index)
            )}

            <AddMenuBtn />
          </GridDropZone>
        ) : (
          <GridDropZone
            id="items"
            boxesPerRow={MQ1200 ? (MQ900 ? 1 : 2) : MQ1600 ? 3 : 4}
            rowHeight={
              SScreen || TScreen || LSScreen ? 140 : GoldFold ? 135 : 175
            }
            style={{ height: height - 40 }}
          >
            <AddMenuBtn />
            <GridItem>{null}</GridItem>
          </GridDropZone>
        )}

        {/* Delete Dialog */}
        <React.Suspense fallback={<></>}>
          <CustomAlertDialog
            dialogData={deleteDialogData}
            cancelBtnTitle={ConstantsHelper.cancelTitle}
            okBtnTitle={ConstantsHelper.deleteTitle}
            onCloseRequired={false}
            loading={apiLoader}
            onClose={() => setDeleteDialogData(dgDefVal)}
            onCancel={() => setDeleteDialogData(dgDefVal)}
            onOkClick={() => {
              if (deleteDialogData?.dataObject) {
                // delete data api calling
                const { id } = deleteDialogData?.dataObject;
                if (deleteDialogData?.actionType === "delete_menu_value") {
                  trackPromise(
                    deleteMenuData({
                      id: Number(id),
                    })
                  );
                }
              }
            }}
          />
        </React.Suspense>
      </GridContextProvider>

      <Menu
        id="long-menu"
        MenuListProps={{ "aria-labelledby": "long-button" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{ style: { width: "17ch" } }}
      >
        {Array.isArray(options) &&
          options?.length > 0 &&
          options?.map((option: any, index: number) => (
            <MenuItem
              key={index}
              onClick={(e: any) => {
                option?.click(e);
                setAnchorEl(null);
              }}
              disabled={
                !currItem?.is_active &&
                (option?.name === "Preview" || option?.name === "Generated QR")
              }
            >
              <Box
                component={"img"}
                src={option.icon}
                sx={{
                  height: 16,
                  width: 18,
                  color: "gray",
                  mr: 1,
                }}
              />
              <Typography sx={{ fontWeight: Fonts.font400 }}>
                {option?.name}
              </Typography>
            </MenuItem>
          ))}
      </Menu>

      {(dialog?.open || openQr?.open) && (
        <InformationSettings
          dialog={dialog}
          openDialog={openDialog}
          openQr={openQr}
          setOpenQr={setOpenQr}
          fieldArr={fieldArr}
          setFieldArr={setFieldArr}
          setDragItems={setDragItems}
          qrValue={`${urlsHelper.serverUrl}${AppUtils.getDomainName()}/vmq/${
            currItem?.id
          }`}
        />
      )}
    </>
  );
};
export default MenuList;
